import React, { useEffect, useState } from 'react';
import { Geography, QueryItem } from '../models/DataSource';
import { Storefront } from '../models/StorefrontResponse';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import geographyData from '../assets/data/geography.json';

interface Props {
    queryItem: QueryItem;
    storefront: Storefront
    updateValue:(value: string) => void;
}

const QueryFieldCustom = (props: Props) => {
    const [selectValue, setSelectValue] = useState<string[]>([]); 
    const [geography, setGeography] = useState<Geography>({state: {}, county: {}, metro_area: {}, scf: {}});

    useEffect(() => {
        console.log("Custom Field Loaded:", props.queryItem.field.key)
        if (props.queryItem.field.key === "secondary_state_countycode" || props.queryItem.field.key === "state_countycode" || props.queryItem.field.key === "state" || props.queryItem.field.key === "secondary_state" || props.queryItem.field.key === "scf") {
            switch (props.queryItem.field.key) {
                case "secondary_state_countycode":
                    //setSelectValue(["AK010"]);
                    break;
                case "state_countycode":
                    //setSelectValue(["AK010"]);
                    break;
                case "state":
                    setSelectValue(["AK"]);
                    break;
                case "secondary_state":
                    setSelectValue(["AK"]);
                    break;
                case "scf":
                    setSelectValue(["005"]);
                    break;
                default:
                    break;
            }
            // Load geography data 
            setGeography(geographyData as Geography);
        }
    }, [props.queryItem.field.key])

    const handleSelectValueChange = (event: SelectChangeEvent<typeof selectValue>) => {
        const {target: { value },} = event;
        setSelectValue(typeof value === 'string' ? value.split(',') : value);
        console.log("Selected Values:", typeof value === 'string' ? value.split(',') : value);
        props.updateValue(value as string);
    }

    return (
        <Box>
            { props.queryItem.field.key === 'state_countycode' || props.queryItem.field.key === 'secondary_state_countycode'
                ? <FormControl fullWidth size="small" sx={{width:300}}>
                <InputLabel>Values</InputLabel>
                <Select label="Values" multiple onChange={handleSelectValueChange} size="small" value={selectValue} MenuProps={{
                                            sx: {
                                              "&& .Mui-selected": {
                                                backgroundColor: props.storefront.activeColors.leftMenu.icon
                                              }
                                            }
                                          }}>
                    {Object.entries(geography.county).map(([key, value]) => (
                        <MenuItem value={key} key={key}>{value}</MenuItem>
                    ))}
                </Select>
                </FormControl>
                : props.queryItem.field.key === 'state'
                ? <FormControl fullWidth size="small" sx={{width:300}}>
                <InputLabel>Values</InputLabel>
                <Select label="Values" multiple onChange={handleSelectValueChange} size="small" value={selectValue} MenuProps={{
                                            sx: {
                                              "&& .Mui-selected": {
                                                backgroundColor: props.storefront.activeColors.leftMenu.icon
                                              }
                                            }
                                          }}>
                    {Object.entries(geography.state).map(([key, value]) => (
                        <MenuItem value={key} key={key}>{value}</MenuItem>
                    ))}
                </Select>
                </FormControl>
                : props.queryItem.field.key === 'secondary_state'
                ? <FormControl fullWidth size="small" sx={{width:300}}>
                <InputLabel>Values</InputLabel>
                <Select label="Values" multiple onChange={handleSelectValueChange} size="small" value={selectValue} MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                }
                                            }
                                            }}>
                                            
                    {Object.entries(geography.state).map(([key, value]) => (
                        <MenuItem value={key} key={key}>{value}</MenuItem>
                    ))}
                </Select>
                </FormControl>
                : props.queryItem.field.key === 'scf'
                ? <FormControl fullWidth size="small" sx={{width:300}}>
                <InputLabel>Values</InputLabel>
                <Select label="Values" onChange={handleSelectValueChange} multiple size="small" value={selectValue} MenuProps={{
                                            sx: {
                                                "&& .Mui-selected": {
                                                backgroundColor: props.storefront.activeColors.leftMenu.icon
                                                }
                                            }
                                            }}>
                    {Object.entries(geography.scf).map(([key, value]) => (
                        <MenuItem value={key} key={key}>{value}</MenuItem>
                    ))}
                </Select>
                </FormControl>
                : <Typography>Unknown Field</Typography>
            }
        </Box>
    )
}

export default QueryFieldCustom;