import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Order } from '../models/OrdersResponse';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { httpPost, APIEndpoint, EndpointType } from "../utils/apiService";
import Alert from '@mui/material/Alert';
import { ExportOrderRequest, ExportOrderResponse, HighLevelExportSettings } from "../models/ExportOrder"
import { useState, useEffect } from 'react';

interface Props {
    Order?: Order,
  }
  
  
  export default function ExportToHighIQ(props: Props) {
    const [apiKey, setApiKey] = useState("")
    const [exportErrorVisibility, setExportErrorVisibility] = useState("none")
    const [exportSuccessVisibility, setExportSuccessVisibility] = useState("none")
    const [errorMessage, setErrorMessage] = useState("")
    const [exportButtonDisabled, setExportButtonDisabled] = useState(false)

    useEffect(() => {
        const highLevelApiKey = localStorage.getItem("highLevelApiKey")
        if (highLevelApiKey != null) {
            setApiKey(highLevelApiKey)
        }
    })

    const submitExport = () => {
        if (apiKey.trim() == "") {
            setErrorMessage("The API Key must be specified!")
            setExportErrorVisibility("flex")
            return
        }
        
        setExportErrorVisibility("none")
        const exportRequest = {} as ExportOrderRequest
        if (props.Order) {
            exportRequest.orderID = props.Order.id!
        }
        exportRequest.destination = "highiq"

        const exportSettings = {} as HighLevelExportSettings
        exportSettings.normalizeAddresses = true
        exportSettings.apiKey = apiKey
        setExportButtonDisabled(true)
        setExportSuccessVisibility("none")
        exportRequest.highLevelExportSettings = exportSettings

        const apiURL =  APIEndpoint(EndpointType.ExportOrder);
        httpPost(apiURL, JSON.stringify(exportRequest))
        .then((data) => {
            const exportOrderResponse = data as ExportOrderResponse;
            
            if (exportOrderResponse.status == 'error') {
                setExportErrorVisibility("flex")
                setErrorMessage(exportOrderResponse.errorMessage)
                setExportButtonDisabled(false)
            } else {
                setExportErrorVisibility("none")
                setExportSuccessVisibility("flex")
                // Save location/api key to local storage
                localStorage.setItem("highLevelApiKey", apiKey)
            }
        })
        .catch((error) => { 
            setErrorMessage(error + "")
            setExportErrorVisibility("flex")
            setExportButtonDisabled(false)
        });
    }

    const apiKeyChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setApiKey(event.target.value)
    }

    const leadCount = () => {
        let count = 0
        if (props.Order?.products != undefined) {
            for (const product of props.Order.products) {
                count += product.quantity
            }
        }

        return count
    }

    return (
    <Stack>
        <Box>All {leadCount()} leads in this order will be sent to High i.Q.'s CRM.</Box>
        <Box sx={{ minWidth: 120, marginRight: 1 }}>
        <FormControl sx={{marginTop:3}} fullWidth>
            <Stack sx={{marginTop:1}}>
                <TextField label="API Key" variant="outlined" onChange={apiKeyChanged} value={apiKey}/>
                <Typography variant="caption">The API Key is an identifier associated with your High i.Q. CRM account. To obtain this value, login to your High i.Q. CRM account, select 'Settings' in the bottom-left of the screen and then look in the 'General Information' section at the bottom. The API Key will be obscured by asterisks so you will need to click on the clipboard icon to the right to copy it.</Typography>
            </Stack>
            <Box sx={{marginTop:3, display: 'flex', flexDirection: 'row-reverse'}}>
                <Button variant="contained" onClick={submitExport} disabled={exportButtonDisabled}>Start Export</Button>
                <Alert severity="error" sx={{display:exportErrorVisibility, marginRight:3}}>{errorMessage}</Alert>
                <Alert severity="success" sx={{display:exportSuccessVisibility, marginRight:3}}>Success! The export is now running. You will receive an email when the export has completed.</Alert>
            </Box>
        </FormControl>
        </Box>
    </Stack>
    );
  }
  