import { Accordion, AccordionDetails, AccordionSummary, Alert, Autocomplete, Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Paper, Select, SelectChangeEvent, Snackbar, Stack, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Title from '../components/Title';
import { Storefront } from '../models/StorefrontResponse';
import { useProductsAll } from "../hooks/useProductsAll";
import { Product } from '../models/Products';
import { useBlueprint } from '../hooks/useBlueprint';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useDataSources } from '../hooks/useDataSources';
import { useDataSource } from '../hooks/useDataSource';
import QueryFieldForm from '../components/QueryFieldForm';
import { QueryDataSource, QueryItem } from '../models/DataSource';
import { genUniqueId, generateOrderID } from '../utils/GenerateUniqueID';
import { Blueprint, BlueprintResponse, QueryBlueprint, QueryFilter } from '../models/Blueprint';
import { useBlueprintCounts } from '../hooks/useBlueprintCounts';
import { httpPost, APIEndpoint, EndpointType, httpPostNoAuth, getEnvironment } from "../utils/apiService";
import CloseIcon from "@mui/icons-material/Close";
import { useBlueprints } from '../hooks/useBlueprints';
import { AutocompleteUser, useUsers } from '../hooks/useUsers';
import { useWalletBalance } from '../hooks/useWalletBalance';
import { CartItem, ShoppingCart, ShoppingCartResponse } from '../models/Store';
import { User } from '../models/UsersResponse';
import { CheckoutResponse } from '../models/CheckoutResponse';
import { useNavigate } from "react-router-dom";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

interface Props {
    storefront: Storefront
    isTSGUser: boolean
    customer: User | undefined
}

const ListBuilder = (props: Props) => {
    const navigate = useNavigate();
    const [blueprintID, setBlueprintID] = useState("");
    const [openBlueprintID, setOpenBlueprintID] = useState("");
    const [dataSourceID, setDataSourceID] = useState("");
    const [openDataSourceID, setOpenDataSourceID] = useState("");
    const [queryItems, setQueryItems] = useState<QueryItem[]>([]);
    const [dataSourceListDisabled, setDataSourceListDisabled] = useState(false);
    const [blueprintStepExpanded, setBlueprintStepExpanded] = useState(true);
    const [dataSourceStepExpanded, setDataSourceStepExpanded] = useState(false);
    const [queryStepExpanded, setQueryStepExpanded] = useState(false);
    const [purchaseStepExpanded, setPurchaseStepExpanded] = useState(false);
    //const [ouputFieldsStepExpanded, setOutputFieldsStepExpanded] = useState(false);
    const [saveBlueprintStepExpanded, setSaveBlueprintStepExpanded] = useState(false);
    const [selectType, setSelectType] = useState("address_zip");
    const [phoneScores, setPhoneScores] = useState<string[]>(["1", "2", "3", "4", "5"]);
    const [phoneType, setPhoneType] = useState<string>("wireless_priority");
    const [includeDNC, setIncludeDNC] = useState(true);
    const [requireEmails, setRequireEmails] = useState(false);
    const [requirePhones, setRequirePhones] = useState(true);
    const [blueprintCountRequest, setBlueprintCountRequest] = useState<QueryBlueprint>();
    const [outputFields, setOutputFields] = useState<string[]>([]);
    const [buildQueryWarningMsg, setBuildQueryWarningMsg] = useState("");
    const [blueprintName, setBlueprintName] = useState("");
    const [countsBlueprint, setCountsBlueprint] = useState<QueryBlueprint>();
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbarMsg, setSnackbarMsg] = useState("")
    const [scopeGlobal, setScopeGlobal] = useState(false)
    const [customerID, setCustomerID] = useState("");
    const [suppressOrders, setSuppressOrders] = useState(true);
    const [filteredDataSources, setFilteredDataSources] = useState<QueryDataSource[]>([]);
    const [orderSubTotal, setOrderSubTotal] = useState(0);
    const [orderSubTotalFormatted, setOrderSubTotalFormatted] = useState("");
    const [orderTotal, setOrderTotal] = useState(0);
    const [orderTotalFormatted, setOrderTotalFormatted] = useState("");
    const [walletApplied, setWalletApplied] = useState(false)
    const [walletCredits, setWalletCredits] = useState(0)
    const [isBlueprintDirty, setIsBlueprintDirty] = useState(false)
    const [isCountsDirty, setIsCountsDirty] = useState(false)
    const [leadPrice, setLeadPrice] = useState(0.2)
    const [blueprintScopes, setBlueprintScopes] = useState<string[]>([])
    const [blueprintsRefreshKey, setBlueprintsRefreshKey] = useState(0)
    const [internalView, setInternalView] = useState(false)
    const [savedBlueprint, setSavedBlueprint] = useState<Blueprint>()
    const [orderQuantity, setOrderQuantity] = useState(0)
    const [orderQuantityValue, setOrderQuantityValue] = useState("")

    const { productsAll } = useProductsAll("", 1)
    const { blueprint } = useBlueprint(openBlueprintID)
    const { blueprints } = useBlueprints(blueprintsRefreshKey, blueprintScopes)
    const { dataSources, dataSourcesError } = useDataSources(1)
    const { dataSource, dataSourceError } = useDataSource(openDataSourceID)
    const { blueprintCount, blueprintCountLoading, blueprintCountError, blueprintSuppressionCount } = useBlueprintCounts(blueprintCountRequest, suppressOrders, customerID)
    const { walletBalance } = useWalletBalance(customerID, 0)
    const { autocompleteUsers } = useUsers(0);

    useEffect(() => {
        if (props.isTSGUser) {
            setInternalView(true)
        }
        setCustomerID(props.customer?.id || "")
    }, [])

    useEffect(() => {
        const scopes: string[] = []

        if (props.customer && props.customer.id) {
            scopes.push(props.customer.id)
        }

        if (props.isTSGUser && internalView) {
            scopes.push("global")
        } else {
            scopes.push("store")
        }

        setBlueprintScopes(scopes)
        setBlueprintsRefreshKey(blueprintsRefreshKey + 1)
    }, [internalView])

    useEffect(() => {
        if (walletBalance && walletBalance.balance && walletBalance.balance > 0) {
            setWalletApplied(true)
        }
    }, [walletBalance])

    useEffect(() => {
        let filteredProducts = productsAll.filter(product => product.audienceID != 0)
        // Remove the Absentee products from the list
        filteredProducts = filteredProducts.filter(product => !product.name.includes("Absentee"))

        // Add back in a generic Absentee product
        const absenteeProduct: Product = {
            id: "fake_absentee",
            name: "Absentee",
            audienceID: 9999999,
            description: "Absentee",
            price: 0,
            detailedDescription: "",
            productType: "list",
            listCounts: [],
            active: true,
            stripeID: "",
            createdAt: "",
            modifiedAt: "",
            groupName: "",
            infoURL: "",
            priceInfo: { price: 0, stripePriceID: "" },
            outputFields: [],
            features: { noDNC: false, phonesEmails: false },
            stripePrice: "",
            leadType: "",
        }
        filteredProducts.push(absenteeProduct)
    }, [productsAll])

    useEffect(() => {
        if (dataSourcesError != "") {
            console.log("Data Sources Error: ", dataSourcesError)
        }
    }, [dataSourcesError])

    useEffect(() => {
        if (dataSourcesError != "") {
            console.log("Data Source Error: ", dataSourceError)
        }
    }, [dataSourceError])

    useEffect(() => {
        if (dataSource == undefined) {
            return
        }

        // Set default output fields
        setOutputFields(getWhitelistedOutputFields())
    }, [dataSource])

    useEffect(() => {
        if (dataSources == undefined) {
            return
        }

        const filtered: QueryDataSource[] = []
        for (const ds of dataSources) {
            if (ds.id == 13 || ds.id == 16 || ds.id == 17 || ds.id == 29 || ds.id == 31 || ds.id == 32 || ds.id == 36 || ds.id == 50) {
                filtered.push(ds)
            }
        }

        setFilteredDataSources(filtered)
    }, [dataSources])

    useEffect(() => {
        calcOrderTotals()
    }, [walletApplied, orderQuantity])

    useEffect(() => {
        if (blueprint == undefined) {
            if (dataSource && dataSource.dedupes && dataSource.dedupes.length > 0) {
                setSelectType(dataSource?.dedupes[0].field)
            }
            return
        }

        if (blueprint.queryBlueprint.data_source_id != 0) {
            setDataSourceID(blueprint.queryBlueprint.data_source_id + "")
            setOpenDataSourceID(blueprint.queryBlueprint.data_source_id + "")
        }

        if (dataSource == undefined) {
            return
        }

        const queryItems: QueryItem[] = []
        // let foundZipField = false
        for (const queryFilter of blueprint.queryBlueprint.filters || []) {
            const newQueryItem: QueryItem = {
                id: genUniqueId(),
                filter: queryFilter,
                field: dataSource?.fields?.find(field => field.key == queryFilter.field) || { key: "", values: {}, range: false }
            }
            queryItems.push(newQueryItem)
        }

        setQueryItems(queryItems)
        setQueryStepExpanded(true)
        setDataSourceListDisabled(true)
        setDataSourceStepExpanded(false)
        setIncludeDNC(blueprint.queryBlueprint.dnc != undefined && blueprint.queryBlueprint.dnc == true)
        setRequireEmails(blueprint.queryBlueprint.emails === "all")
        setRequirePhones(blueprint.queryBlueprint.phones === "all")
        setSelectType(blueprint.queryBlueprint.select_type ?? "address_zip")
        setPhoneScores(blueprint.queryBlueprint.phone_score ?? [])
        setPhoneType(blueprint.queryBlueprint.phone_type ?? "")

        if (blueprint.access.scope == "user") {
            setBlueprintName(blueprint.name)
            setCustomerID(blueprint.access.value || "")
        } else {
            setBlueprintName("")
            setScopeGlobal(false)
        }
    }, [blueprint, dataSource])

    useEffect(() => {
        calcOrderTotals()

        if (blueprintCount && blueprintCount > 0) {
            setIsCountsDirty(false)
            //setOutputFieldsStepExpanded(true)
            setSaveBlueprintStepExpanded(true)
            setOrderQuantityValue(blueprintCount + "")
        }
    }, [blueprintCount])

    useEffect(() => {

        const qty = parseInt(orderQuantityValue)

        if (qty < 0 || qty > blueprintCount) {
            setSnackbarMsg("Invalid quantity. Must be between 0 and " + blueprintCount)
            setOpenSnackbar(true)
            return
        }

        if (qty) {
            setOrderQuantity(qty)
        }
    }, [orderQuantityValue])

    const handleTemplateChange = (event: SelectChangeEvent) => {
        if (event.target.value != undefined) {
            setBlueprintID(event.target.value)
        }
    };

    const handleDataSourceChange = (event: SelectChangeEvent) => {
        if (event.target.value != undefined) {
            setDataSourceID(event.target.value)
        }
    };

    const handleSelectTypeChange = (event: SelectChangeEvent) => {
        if (event.target.value != undefined) {
            setSelectType(event.target.value)
        }
    };

    const handlePhoneTypeChange = (event: SelectChangeEvent) => {
        if (event.target.value != undefined) {
            setPhoneType(event.target.value)
        }
    }

    const openDataSourceButtonClicked = () => {
        setBlueprintName("")
        setSelectType("")
        setOpenDataSourceID(dataSourceID)
        setQueryStepExpanded(true)
        setOpenBlueprintID("")
        setQueryItems([])
    }

    const openBlueprintButtonClicked = () => {
        setOpenBlueprintID(blueprintID)
    }

    const createNewButtonClicked = () => {
        setBlueprintStepExpanded(false)
        setDataSourceStepExpanded(true)
        setBlueprintID("")
        setOpenBlueprintID("")
        setDataSourceListDisabled(false)
        setQueryItems([])
    }

    const blueprintAccordionChanged = () => {
        setBlueprintStepExpanded(!blueprintStepExpanded)
    }

    const dataSourceAccordionChanged = () => {
        setDataSourceStepExpanded(!dataSourceStepExpanded)
    }

    const queryAccordionChanged = () => {
        setQueryStepExpanded(!queryStepExpanded)
    }

    const purchaseAccordionChanged = () => {
        setPurchaseStepExpanded(!purchaseStepExpanded)
    }

    // const outputFieldsAccordionChanged = () => {
    //     setOutputFieldsStepExpanded(!ouputFieldsStepExpanded)
    // }

    const saveBlueprintAccordionChanged = () => {
        setSaveBlueprintStepExpanded(!saveBlueprintStepExpanded)
    }

    const includeDNCChanged = () => {
        setIncludeDNC(!includeDNC)
    }

    const internalViewChanged = () => {
        setInternalView(!internalView)
    }

    const requireEmailsChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRequireEmails(checked)
    }

    const requirePhonesChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setRequirePhones(checked)
    }

    const blueprintNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBlueprintName(event.target.value)
    }

    const scopeGlobalChanged = () => {
        setScopeGlobal(!scopeGlobal)
    }

    const suppressOrdersChanged = () => {
        setSuppressOrders(!suppressOrders)
    }

    const handlePhoneScoresChange = (event: SelectChangeEvent<typeof phoneScores>) => {
        const {
            target: { value },
        } = event;
        setPhoneScores(
            typeof value === 'string' ? value.split(',') : value,
        );
    }

    const addFieldButtonClicked = () => {
        if (dataSource == undefined) {

            return
        }

        const newQueryItem: QueryItem = {
            id: genUniqueId(),
            filter: { field: "", condition: "and", values: [], omit: false },
            field: { key: "", values: {}, range: false }
        }
        setQueryItems([...queryItems, newQueryItem]);
    }

    const runCountsButtonClicked = () => {
        const newBlueprint = assembleBlueprint()
        if (newBlueprint == undefined) {
            return
        }

        setBlueprintCountRequest(newBlueprint)
    }

    const removeFieldFromList = (fieldID: string) => {
        const newQueryItems = queryItems.filter(queryItem => queryItem.id != fieldID)
        setQueryItems(newQueryItems)
    }

    const updateQueryFilter = (filter: QueryFilter, queryItemID: string) => {
        setIsBlueprintDirty(true)
        setIsCountsDirty(true)
        const newQueryItems = queryItems.map(queryItem => {
            if (queryItem.id == queryItemID) {
                queryItem.filter = filter
                queryItem.field = dataSource?.fields?.find(field => field.key == filter.field) || { key: "", values: {}, range: false }
            }
            return queryItem
        })

        setQueryItems(newQueryItems)
    }

    const assembleBlueprint = (): QueryBlueprint | undefined => {
        setBuildQueryWarningMsg("")
        const filters = assembleFilters()

        if (filters.length == 0) {
            return undefined
        }


        const newBlueprint: QueryBlueprint = {
            name: rootUserEmail() + "_" + genUniqueId() + "_lb",
            data_source_id: parseInt(dataSourceID),
            match_type: "",
            phones: requirePhones ? "all" : "none",
            emails: requireEmails ? "all" : "none",
            filters: assembleFilters(),
            dedupe_within: "",
            output_fields: []
        }

        if (dataSourceID == "50") {
            //newBlueprint.match_type = "address_zip"
        } else {
            newBlueprint.select_type = selectType
            newBlueprint.phone_type = phoneType
            newBlueprint.phone_score = phoneScores
            newBlueprint.dnc = includeDNC
        }

        setCountsBlueprint(newBlueprint)
        return newBlueprint
    }

    const assembleFilters = (): QueryFilter[] => {
        const filters: QueryFilter[] = []
        for (const queryItem of queryItems) {
            let foundNonValue = false
            for (const value of queryItem.filter.values) {
                if (value == "") {
                    foundNonValue = true
                    break
                }
            }
            if (foundNonValue) {
                setBuildQueryWarningMsg("One or more fields have no value. Please add value(s) to all fields before running counts")
                return []
            }
            filters.push(queryItem.filter)
        }

        // if the datasource is Absentee (#50), the DNC fields need to be included filters
        if (dataSourceID == "50" && includeDNC) {
            filters.push({ field: "wireless_dnc", condition: "and", values: ["Y"], omit: true })
            filters.push({ field: "landline_dnc", condition: "and", values: ["Y"], omit: true })
        }

        return filters
    }

    const rootUserEmail = (): string => {
        const email = props.customer && props.customer.email
        if (email === undefined) {
            return "unknown"
        }

        const emailParts = email.split('@'); const firstPart = emailParts[0]; return firstPart;
    }

    // const handleOutputFieldChange = (event: SelectChangeEvent<typeof outputFields>) => {
    //     const {
    //         target: { value },
    //     } = event;
    //     setOutputFields(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    // function getStyles(name: string, personName: readonly string[], theme: Theme) {
    //     return {
    //         fontWeight:
    //             personName.indexOf(name) === -1
    //                 ? theme.typography.fontWeightRegular
    //                 : theme.typography.fontWeightMedium,
    //     };
    // }

    function getWhitelistedQueryFields(): string[] {
        if (dataSource == undefined || dataSource.fields == undefined) {
            return []
        }

        switch (dataSource.id) {
            case 13:
                return consumer13QueryFieldWhiteList
            case 16:
                return businessEmail16QueryFieldWhiteList
            case 17:
                return business17QueryFieldWhiteList
            case 29:
                return timeshare29QueryFieldWhiteList
            case 50:
                return absentee50QueryFieldWhiteList
            case 31:
                return consumerMortgage31QueryFieldWhiteList
            case 32:
                return businessMortgage32QueryFieldWhiteList
            case 36:
                return newMover36QueryFieldWhiteList
        }

        return []
    }

    function getWhitelistedOutputFields(): string[] {
        if (dataSource == undefined || dataSource.fields == undefined) {
            return []
        }

        switch (dataSource.id) {
            case 13:
                return consumer13OutputFieldWhiteList
            case 16:
                return businessEmail16OutputFieldWhiteList
            case 17:
                return business17OutputFieldWhiteList
            case 29:
                return timeshare29OutputFieldWhiteList
            case 50:
                return absentee50OutputFieldWhiteList
            case 31:
                return consumerMortgage31OutputFieldWhitelist
            case 32:
                return businessMortgage32OutputFieldWhiteList
            case 36:
                return newMover36OutputFieldWhiteList
        }

        return []
    }

    const saveBlueprintClicked = () => {
        if (countsBlueprint == undefined || isCountsDirty) {
            setSnackbarMsg("Cannot save Blueprint. Please run counts first.")
            setOpenSnackbar(true)
            return
        }

        if (blueprintName.trim() == "") {
            setSnackbarMsg("Please enter a name for the Blueprint")
            setOpenSnackbar(true)
            return
        }

        let bprint = blueprint
        if (bprint == undefined) {
            bprint = {
                id: "",
                name: blueprintName,
                queryBlueprint: countsBlueprint,
                createdBy: props.customer && props.customer.id || "",
                createdAt: "",
                modifiedAt: "",
                access: {
                    scope: scopeGlobal ? "global" : "user"
                }
            }
            bprint.queryBlueprint.output_fields = outputFields
        } else {
            bprint.queryBlueprint = countsBlueprint
            bprint.createdBy = props.customer && props.customer.id || ""
            bprint.access = {
                scope: scopeGlobal ? "global" : "user"
            }

            bprint.queryBlueprint.output_fields = outputFields

            if (bprint.name != blueprintName && blueprintID != "") {
                bprint.id = ""
            }
            bprint.name = blueprintName
        }

        if (bprint.access.scope == "user") {
            bprint.access.value = props.customer && props.customer.id || ""
        }

        const apiURL = APIEndpoint(EndpointType.Blueprints)
        httpPost(apiURL, JSON.stringify(bprint))
            .then((data) => {
                const response = data as BlueprintResponse;

                if (response.status == 'error') {
                    console.log("Error saving blueprint: " + response.errorMessage)
                    setSnackbarMsg("Failed to save Blueprint..." + response.errorMessage || "Unknown error")
                    setOpenSnackbar(true)
                } else {
                    setIsBlueprintDirty(false)
                    setSnackbarMsg("Blueprint saved successfully!")
                    setOpenSnackbar(true)
                    setPurchaseStepExpanded(true)
                    setSavedBlueprint(response.blueprint)
                }
            })
            .catch((error) => {
                setSnackbarMsg(error.message)
                setOpenSnackbar(true)
            });
    }

    const customerIDChanged = (event: React.SyntheticEvent<Element, Event>, value: AutocompleteUser | null) => {
        if (value == null) {
            setCustomerID("")
        } else {
            setCustomerID(value.id)
        }
    }

    const getFriendlyAccessDescription = (blueprint: Blueprint): string => {
        if (blueprint.access.scope == "global" || blueprint.access.scope == "store") {
            return ""
        } else if (blueprint.access.scope == "storefront") {
            if (blueprint.access.value == undefined) {
                return "Storefront"
            }
            return blueprint.access.value
        } else {
            return "[Personal]"
        }
    }

    const getWalletBalance = (): number => {
        if (walletBalance && walletBalance.balance) {
            return walletBalance.balance
        }

        return 0
    }

    function calcOrderTotals() {
        if (countsBlueprint == undefined) {
            return
        }

        let credits = 0

        if (walletApplied) {
            if (getWalletBalance() >= orderQuantity) {
                credits = orderQuantity
            } else {
                credits = getWalletBalance()
            }
        }

        const leadBalance = orderQuantity - credits
        const subTotal = getLeadPrice(orderQuantity) * orderQuantity
        const pricePerLead = getLeadPrice(leadBalance)
        const total = pricePerLead * leadBalance

        setWalletCredits(credits)
        setOrderSubTotal(subTotal);
        setOrderSubTotalFormatted(formatter.format(subTotal));
        setOrderTotal(total)
        setOrderTotalFormatted(formatter.format(total));
        setLeadPrice(pricePerLead)
    }

    function getLeadPrice(quantity: number): number {
        let price = 0.2
        if (quantity < 2500) {
            price = 0.2
        } else if (quantity < 5000) {
            price = 0.18
        } else if (quantity < 10000) {
            price = 0.16
        } else if (quantity >= 10000) {
            price = 0.14
        }
        return price
    }

    function getLeadStripePriceID(quantity: number): string {
        if (getEnvironment() == "dev") {
            if (quantity < 2500) {
                return "price_1PVJvkCxNi5DvLVrvxMwE0zk"
            } else if (quantity < 5000) {
                return "price_1PVJxUCxNi5DvLVrBx9kPh7l"
            } else if (quantity < 10000) {
                return "price_1PVJyzCxNi5DvLVrzG6sB5C4"
            } else if (quantity >= 10000) {
                return "price_1PVK0cCxNi5DvLVrziHXw4IM"
            }
        } else {
            if (quantity < 2500) {
                return "price_1PVK4gCxNi5DvLVrL090ac26"
            } else if (quantity < 5000) {
                return "price_1PVK5KCxNi5DvLVrO56NHm3Z"
            } else if (quantity < 10000) {
                return "price_1PVK5qCxNi5DvLVrR3ytbAmY"
            } else if (quantity >= 10000) {
                return "price_1PVK6JCxNi5DvLVrSD2uSprq"
            }
        }
        return ""
    }

    const handleApplyWalletChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWalletApplied(event.target.checked);
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const getStripeCustomProductID = (): string => {
        if (getEnvironment() == "dev") {
            return "prod_Pv636v3I3I6hgr"
        }

        return "prod_Pv65QabwUkOvYl"
    }

    const createProduct = (bp: Blueprint | undefined): Product => {
        if (!bp) {
            console.log("Could not find blueprint or product")
            return {
                audienceID: 0,
                description: "",
                detailedDescription: "",
                id: "",
                name: "",
                price: 0,
                productType: "",
                listCounts: [],
                active: false,
                stripeID: "",
                createdAt: "",
                modifiedAt: "",
                groupName: "",
                infoURL: "",
                priceInfo: {
                    price: 0,
                    stripePriceID: ""
                },
                outputFields: [],
                features: {
                    noDNC: false,
                    phonesEmails: false,
                },
                stripePrice: "",
                leadType: "",
            }
        }

        return {
            audienceID: 0,
            description: bp.name,
            detailedDescription: bp.name,
            id: bp.id,
            name: bp.name,
            price: leadPrice,
            productType: "custom_leads",
            listCounts: [],
            active: true,
            stripeID: getStripeCustomProductID(),
            createdAt: "",
            modifiedAt: "",
            groupName: "",
            infoURL: "",
            priceInfo: {
                price: leadPrice,
                stripePriceID: getLeadStripePriceID(blueprintCount)
            },
            outputFields: [],
            features: {
                noDNC: false,
                phonesEmails: false,
            },
            stripePrice: getLeadStripePriceID(blueprintCount),
            leadType: "",
        }
    }

    const placeOrder = () => {
        setOpenSnackbar(false)

        if (savedBlueprint == undefined) {
            setSnackbarMsg("You must save the blueprint before placing an order")
            setOpenSnackbar(true)
            return
        }

        if (blueprintCount == 0 || isCountsDirty) {
            setSnackbarMsg("You must run counts before placing an order. The count must be greater than 0")
            setOpenSnackbar(true)
            return
        }

        if (internalView && isBlueprintDirty) {
            setSnackbarMsg("You must save the blueprint before placing an order")
            setOpenSnackbar(true)
            return
        }

        const cartItems: CartItem[] = []

        if (blueprints == undefined) {
            console.log("Blueprints not loaded yet")
            return
        }

        const cartItem: CartItem = {
            id: genUniqueId(),
            product: createProduct(savedBlueprint),
            quantity: orderQuantity,
            postalCodes: getPostalCodes(),
            suppressionCheck: {
                checkCompleted: true,
                availableCount: 0,
                requestedCount: 0,
                isCountFulfilled: true
            }
        }

        cartItems.push(cartItem)

        const cart: ShoppingCart = {
            items: cartItems,
            cartToken: genUniqueId(),
            createdAt: "",
            modifiedAt: "",
            hubspotDealID: "",
            status: "checkout",
            userID: customerID,
            subtotal: orderSubTotal,
            total: orderTotal,
            couponCode: "",
            discount: 0,
            storefrontID: props.storefront.id,
            discountDescription: "",
            orderID: generateOrderID(),
            applyWallet: walletApplied,
            walletCreditsApplied: walletCredits
        }

        console.log("Cart: " + JSON.stringify(cart))

        if (orderTotal == 0) {
        const apiURL = APIEndpoint(EndpointType.StoreCart)
        httpPostNoAuth(apiURL, JSON.stringify(cart))
            .then((data) => {
                const shoppingCartResponse = data as ShoppingCartResponse;

                if (shoppingCartResponse.status == 'error') {
                    setSnackbarMsg("Error saving shopping cart: " + shoppingCartResponse.errorMessage)
                    setOpenSnackbar(true)
                } else {
                    setSnackbarMsg("Order placed successfully!")
                    setOpenSnackbar(true)
                    navigate("/checkoutsuccess")
                }
            })
            .catch((error) => {
                console.log("saveShoppingCart: " + error.message)
            });
        } else {
            console.log("requesting order...")
            const apiURL =  APIEndpoint(EndpointType.Checkout)
            httpPostNoAuth(apiURL, JSON.stringify(cart))
            .then((data) => {
                const checkoutResponse = data as CheckoutResponse;
                
                if (checkoutResponse.status == 'error') {
                    console.log("Error checking-out: " + checkoutResponse.errorMessage)
                    setSnackbarMsg("Error Checking out. " + checkoutResponse.errorMessage)
                    setOpenSnackbar(true)
                } else {
                    console.log("Checkout session created successfuly...redirecting to checkout page")
                    window.location.replace(checkoutResponse.redirectURL);
                }
            })
            .catch((error) => { 
                console.log("saveShoppingCart: " + error.message)
            });
        }
    }

    const orderQuantityChanged = (event: React.ChangeEvent<{ value: string }>) => {
        setOrderQuantityValue(event.target.value)
    }

    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    // Retrieve the postal codes from the query items
    const getPostalCodes = (): string[] => {
        const postalCodes: string[] = []

        for (const queryItem of queryItems) {
            if (queryItem.filter.field == "zip") {
                for (const value of queryItem.filter.values) {
                    postalCodes.push(value)
                }
            }
        }

        return postalCodes
    }

    const snackbarAction = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const consumer13QueryFieldWhiteList: string[] = ["city_state", "msa_code", "state", "zip", "state_countycode", "zip_radius", "zip4_radius", "birth_month", "birth_year", "credit_rating", "est_age", "est_income", "ethnic_code", "ethnic_group", "gender", "home_purchase_date", "home_market_value", "home_value", "homeowner", "investment_properties_owned", "language", "loan_to_value", "lor", "marital_status", "most_recent_mortgage_amount", "most_recent_mortgage_date", "most_recent_mortgage_interest_rate_type", "most_recent_mortgage_loan_type_code", "net_worth", "number_of_lines_of_credit", "pool", "presence_of_children", "property_type", "purchase_price", "year_built",];
    const consumer13OutputFieldWhiteList: string[] = ["first", "middle", "last", "address", "city", "state", "zip", "zip4", "phone", "phone_type", "dnc", "phone_2", "phone_type_2", "dnc_2", "email", "latitude", "longitude", "homeowner", "loan_to_value", "home_purchase_date", "lor", "property_type", "dwelling", "year_built", "most_recent_mortgage_date", "most_recent_mortgage_amount", "est_age", "birth_month", "birth_year", "marital_status", "presence_of_children"];
    const consumerMortgage31QueryFieldWhiteList: string[] = ["city_state", "state_countycode", "state", "zip", "secondary_city_state", "secondary_state_countycode", "secondary_state", "secondary_zip", "absentee_owner_computed", "acres", "bedrooms", "credit_rating", "ethnic_code", "exact_age", "gender_code", "income_estimated_household", "land_use", "living_square_feet", "loan_to_value", "most_recent_mortgage_amount", "most_recent_mortgage_date", "most_recent_mortgage_interest_rate_type", "most_recent_mortgage_interest_rate", "owner_occupied", "pool", "property_indicator", "sale_date", "sale_price", "stories_number", "total_baths", "total_value_calculated", "year_built",]
    const consumerMortgage31OutputFieldWhitelist: string[] = ["individual_name", "first", "last", "address", "city", "state", "zip", "zip4", "secondary_address", "secondary_city", "secondary_state", "secondary_zip", "secondary_zip4", "phone", "phone_2", "phone_type", "phone_type_2", "dnc", "dnc_2", "email", "loan_to_value", "year_built", "stories_number", "total_baths", "bedrooms", "land_use", "sale_date", "sale_price", "living_square_feet", "most_recent_mortgage_amount", "most_recent_mortgage_date", "situs_latitude", "situs_longitude",]
    const absentee50QueryFieldWhiteList: string[] = ["pzip"]
    const absentee50OutputFieldWhiteList: string[] = ["first", "middle", "last", "owner_address", "owncity", "ownstate", "ownzip_code", "ownzip4", "phone1", "phone1_dnc", "phone2", "phone2_dnc", "email", "land_use", "prop_address", "propcity", "propstate", "propzip", "latitude", "longitude", "sale_price", "sale_date", "level", "bedrooms", "total_baths", "living_square_feet", "year_built", "loan_to_value", "most_recent_mortgage_date", "most_recent_mortgage_amount"];
    const timeshare29QueryFieldWhiteList: string[] = ["state", "zip_radius", "zip", "resort"]
    const timeshare29OutputFieldWhiteList: string[] = ["phone", "phone_type", "first", "last", "address1", "city", "state", "zip"]
    const businessEmail16QueryFieldWhiteList: string[] = ["city_state", "msa_code", "state", "state_countycode", "c_level", "location_type", "public", "sic_code"]
    const businessEmail16OutputFieldWhiteList: string[] = ["company", "first", "last", "address1", "address2", "city", "state", "state_countycode", "zip", "zip4", "email", "phone", "c_level", "sic_code", "sic_description", "location_type"]
    const business17QueryFieldWhiteList: string[] = ["city_state", "state", "state_countycode", "c_level", "contact_name_present", "employee_count", "location_type", "public", "sale_code", "sic_code", "title", "title_group", "year_start"]
    const business17OutputFieldWhiteList: string[] = ["company", "first", "last", "title", "address1", "address", "city", "state", "state_countycode", "zip", "zip4", "phone", "sic_code_raw", "sic_description", "sale_code", "employee_count", "email"]
    const businessMortgage32QueryFieldWhiteList: string[] = ["secondary_state_countycode", "secondary_city_state", "secondary_state", "secondary_zip", "secondary_zip_radius", "acres", "company", "land_use", "property_indicator"]
    const businessMortgage32OutputFieldWhiteList: string[] = ["first", "last", "company", "address", "city", "state", "zip", "zip4", "secondary_address", "secondary_city", "secondary_state", "secondary_zip"]
    const newMover36QueryFieldWhiteList: string[] = ["countycode", "state", "zip","zip4", "dwelling_type","date_of_name_mmyyyy","move_distance_code"]
    const newMover36OutputFieldWhiteList: string[] = ["first", "last", "address", "city", "state", "zip", "zip4", "phone1", "phone2", "dnc", "email","date_of_name_mmyyyy", "distance_moved", "dwelling_type", "ethnicity", "gender", "move_distance_code", "moved_out_of_state", "moved_out_of_zip","prev_st_addr_1","prev_city","prev_state","prev_zip"]

    return (
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                        <Title>List Builder</Title>
                        <Grid sx={{}}>
                            {props.isTSGUser &&
                                <Grid item xs={4} sx={{ml:2}}>
                                    <FormControlLabel control={<Switch checked={internalView}/>} label="TSG Internal Advanced View" value={internalView} onChange={internalViewChanged} />
                                </Grid>
                            }
                            <Accordion expanded={blueprintStepExpanded} onChange={blueprintAccordionChanged} sx={{backgroundColor:props.storefront.activeColors.mainPage.headerBackground}}>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography sx={{ fontWeight: "bold" }}>1: Start from an existing Blueprint or create a new one from scratch</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{mb:1, ml:.5}}>Blueprints are the design plans for constructing a list of leads.</Typography>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Blueprint</InputLabel>
                                        <Select labelId="transType" label="Blueprint" onChange={handleTemplateChange} size="small" value={blueprintID}>
                                            {blueprints && blueprints.map((bp) => (
                                                <MenuItem value={bp.id} key={bp.id}>{bp.name} {getFriendlyAccessDescription(bp)}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button variant="contained" color="primary" sx={{ ml: 2 }} onClick={openBlueprintButtonClicked}>Open</Button>
                                    <Button variant="contained" color="primary" sx={{ ml: 2 }} onClick={createNewButtonClicked}>Create New</Button>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={dataSourceStepExpanded} onChange={dataSourceAccordionChanged} sx={{backgroundColor:props.storefront.activeColors.mainPage.headerBackground}}>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography sx={{ fontWeight: "bold" }}>2: Select a data source</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <FormControl fullWidth size="small" sx={{ width: 300 }}>
                                        <InputLabel id="trans-type-label">Data Source</InputLabel>
                                        <Select labelId="transType" disabled={dataSourceListDisabled} label="Data Source" onChange={handleDataSourceChange} size="small" value={dataSourceID}>
                                            {filteredDataSources && filteredDataSources.map((dataSource) => (
                                                <MenuItem key={dataSource.id} value={dataSource.id}>{dataSource.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button variant="contained" disabled={dataSourceListDisabled} color="primary" sx={{ ml: 2 }} onClick={openDataSourceButtonClicked}>Open</Button>
                                    <Typography visibility={dataSourceListDisabled ? "visible" : "hidden"} sx={{ color: "red" }}>Since a blueprint was selected, the Data Source cannot be changed</Typography>
                                    {dataSourceError != "" &&
                                        <Alert severity="error">{dataSourceError.toString()}</Alert>
                                    }
                                    {!internalView &&
                                        <Typography sx={{}}>The data source specifies which database to query from. The most common one is 'Consumer'.</Typography>
                                    }
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={queryStepExpanded} onChange={queryAccordionChanged} sx={{backgroundColor:props.storefront.activeColors.mainPage.headerBackground}}>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography sx={{ fontWeight: "bold" }}>3: Build Query</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {dataSource != undefined
                                        ? <Grid>
                                            <Grid container spacing={2}>
                                                {internalView &&
                                                    <Grid item xs={4}>
                                                        <FormControl fullWidth size="small" sx={{}}>
                                                            <InputLabel id="select-type-label">Select Type</InputLabel>
                                                            <Select labelId="selectType" label="Select Type" onChange={handleSelectTypeChange} size="small" value={selectType} MenuProps={{ sx: { "&& .Mui-selected": { backgroundColor: props.storefront.activeColors.leftMenu.icon } } }}>
                                                                <MenuItem value="" key="">All</MenuItem>
                                                                {dataSource && dataSource.dedupes && dataSource.dedupes.map((dedupe) => (
                                                                    <MenuItem value={dedupe.field} key={dedupe.field}>{dedupe.name} </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {internalView &&
                                                    <Grid item xs={4}>
                                                        <FormControl fullWidth size="small" sx={{}}>
                                                            <InputLabel id="phones-scores-label">Phone Scores</InputLabel>
                                                            <Select sx={{}} multiple labelId="phoneScores" label="Phone Scores"
                                                                onChange={handlePhoneScoresChange} MenuProps={{ sx: { "&& .Mui-selected": { backgroundColor: props.storefront.activeColors.leftMenu.icon } } }} size="small" value={phoneScores}>
                                                                <MenuItem value="1" key="1">1</MenuItem>
                                                                <MenuItem value="2" key="2">2</MenuItem>
                                                                <MenuItem value="3" key="3">3</MenuItem>
                                                                <MenuItem value="4" key="4">4</MenuItem>
                                                                <MenuItem value="5" key="5">5</MenuItem>
                                                                <MenuItem value="6" key="6">6</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                {internalView &&
                                                    <Grid item xs={4}>
                                                        <FormControl fullWidth size="small" sx={{}}>
                                                            <InputLabel id="phones-label">Phones</InputLabel>
                                                            <Select sx={{}} labelId="phones" label="Phones"
                                                                onChange={handlePhoneTypeChange}
                                                                MenuProps={{ sx: { "&& .Mui-selected": { backgroundColor: props.storefront.activeColors.leftMenu.icon } } }} size="small" value={phoneType}>
                                                                <MenuItem value="landline" key="landline">Landlines</MenuItem>
                                                                <MenuItem value="wireless" key="wireless">Mobile</MenuItem>
                                                                <MenuItem value="wireless_priority" key="wireless_priority">Mobile (priority) + Landlines</MenuItem>
                                                                <MenuItem value="landline_priority" key="landline_priority">Landline (priority) + Mobile</MenuItem>
                                                                <MenuItem value="none" key="none">None</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                <Grid item xs={4}>
                                                    <FormControlLabel control={<Switch defaultChecked />} label="Include DNC" value={includeDNC} onChange={includeDNCChanged} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel control={<Switch onChange={requireEmailsChanged} checked={requireEmails} />} label="Require Emails" />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControlLabel control={<Switch checked={requirePhones} onChange={requirePhonesChanged} />} label="Require Phones" />
                                                </Grid>
                                            </Grid>
                                            <List>
                                                {queryItems.map((queryItem) => (
                                                    <ListItem key={queryItem.id} sx={{ mt: 2 }}>
                                                        <QueryFieldForm key={queryItem.id} queryItem={queryItem} dataSource={dataSource} storefront={props.storefront} removeField={removeFieldFromList} updateFilter={updateQueryFilter} whitelistedQueryFields={getWhitelistedQueryFields()} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                            <Button variant="contained" color="primary" sx={{ ml: 0 }} onClick={addFieldButtonClicked}>Add Field</Button>
                                        </Grid>
                                        : <Alert severity="warning">No Data Source Selected</Alert>
                                    }
                                    {buildQueryWarningMsg != "" && <Alert sx={{ mt: 1 }} severity="warning">{buildQueryWarningMsg}</Alert>}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion sx={{backgroundColor:props.storefront.activeColors.mainPage.headerBackground}}>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography sx={{ fontWeight: "bold" }}>4: Run Counts</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {queryItems.length > 0
                                        ? <Grid>
                                            {internalView &&
                                                <Box sx={{ mb: 2 }}>
                                                    <FormControlLabel control={<Switch checked={suppressOrders} onChange={suppressOrdersChanged} />} label="Suppress previous orders" />                                                
                                                    <Autocomplete disablePortal isOptionEqualToValue={(option: AutocompleteUser, value: AutocompleteUser) => option.id === value.id} id="existingCustomer" onChange={customerIDChanged} options={autocompleteUsers} size="small" sx={{ width: 360 }}
                                                        renderInput={(params) => <TextField {...params} label="Customer Name" />} />                                                  
                                                </Box>
                                            }
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Button variant="contained" color="primary" sx={{ ml: 2 }} onClick={runCountsButtonClicked}>Run Counts</Button>
                                                {blueprintCountLoading
                                                    ? <Typography>{blueprintCountLoading ? "Requesting counts..." : ""}</Typography>
                                                    : blueprintCountError != ""
                                                        ? <Alert severity="error">{blueprintCountError}</Alert>
                                                        : <Stack direction="row">
                                                            <Typography>Count Result: {blueprintCount.toLocaleString()}</Typography>
                                                            {suppressOrders && <Typography sx={{ ml: 1 }}>- After suppressing {blueprintSuppressionCount} previous orders</Typography>}
                                                        </Stack>
                                                }
                                            </Stack>
                                        </Grid>
                                        : <Alert severity="warning">Please add fields to the query before running counts</Alert>
                                    }
                                </AccordionDetails>
                            </Accordion>
                            {/* <Accordion expanded={ouputFieldsStepExpanded} onChange={outputFieldsAccordionChanged} sx={{backgroundColor:props.storefront.activeColors.mainPage.headerBackground}}>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography sx={{ fontWeight: "bold" }}>5: Select Output Fields</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {blueprintCount > 0
                                        ? <FormControl sx={{ width: 590 }}>
                                            <InputLabel id="demo-multiple-chip-label">Output Fields</InputLabel>
                                            <Select
                                                labelId="output-fields-label"
                                                id="output-fields-chip"
                                                multiple
                                                value={outputFields}
                                                onChange={handleOutputFieldChange}
                                                input={<OutlinedInput id="select-multiple-output-field" label="Output Fields" />}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip key={value} label={value} />
                                                        ))}
                                                    </Box>
                                                )}
                                                MenuProps={MenuProps}
                                            >
                                                {getWhitelistedOutputFields().map((field) => (
                                                    <MenuItem
                                                        key={field}
                                                        value={field}
                                                        style={getStyles(field, outputFields, theme)}
                                                    >
                                                        {field}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        : <Alert severity="warning">The query must yield at least one record before continuing</Alert>
                                    }
                                </AccordionDetails>
                            </Accordion> */}
                            <Accordion expanded={saveBlueprintStepExpanded} onChange={saveBlueprintAccordionChanged} sx={{backgroundColor:props.storefront.activeColors.mainPage.headerBackground}}>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography sx={{ fontWeight: "bold" }}>5: Save the Blueprint</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {outputFields.length > 0
                                        ? <Grid>
                                            {internalView
                                                ? <Typography>Once the Blueprint is saved, it can be ordered from the "Place New Order" tab. The blueprint name will appear in the order invoice so name it appropriately (i.e., "Distressed leads - Wilma Flintstone").</Typography>
                                                : <Typography>The Blueprint must be saved before it can be ordered.</Typography>
                                            }
                                            <TextField sx={{ mt: 1 }} fullWidth size="small" label="Blueprint Name" variant="outlined" value={blueprintName} onChange={blueprintNameChanged} />
                                            {internalView &&
                                                <FormControlLabel control={<Switch checked={scopeGlobal} onChange={scopeGlobalChanged} />} label="Make available to all users" />
                                            }
                                            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={saveBlueprintClicked}>Save Blueprint</Button>
                                        </Grid>
                                        : <Alert severity="warning">Cannot save Blueprint until output fields are specified</Alert>
                                    }
                                </AccordionDetails>
                            </Accordion>
                            {!internalView &&
                                <Accordion expanded={purchaseStepExpanded} onChange={purchaseAccordionChanged} sx={{backgroundColor:props.storefront.activeColors.mainPage.headerBackground}}>
                                    <AccordionSummary
                                        expandIcon={<ArrowDropDownIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography sx={{ fontWeight: "bold" }}>6: Purchase Leads</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Paper sx={{ width: 1, p: 2, display: "flex", flexDirection: "column", background: (theme) => theme.palette.background.paper }}>
                                            <Typography sx={{ fontWeight: "bold" }}>Order Summary:</Typography>
                                            <Stack direction="row" alignItems="flex-start" sx={{mt:1, mb:1}} spacing={2}>
                                                <TextField id="outlined-basic" label="Order Quantity" variant="outlined" size="medium" value={orderQuantityValue} onChange={orderQuantityChanged} />
                                                <Stack direction="row" alignItems="flex-start">
                                                    <Typography sx={{ width: "80px" }}>Available:</Typography>
                                                    <Typography>{blueprintCount.toLocaleString()}</Typography>
                                                </Stack>
                                                
                                            </Stack>
                                            <Stack direction="row" alignItems="flex-start">
                                                <Typography sx={{ width: "150px" }}>Sub-Total:</Typography>
                                                <Typography>{orderSubTotalFormatted}</Typography>
                                            </Stack>
                                            <Stack direction="row" alignItems="flex-start">
                                                <FormControlLabel control={<Checkbox checked={walletApplied} onChange={handleApplyWalletChange} />} label="Use Wallet" sx={{ mt: -1, width: "145px" }} />
                                                <Box>{walletCredits}</Box>
                                            </Stack>
                                            <Stack direction="row" alignItems="flex-start">
                                                <Typography sx={{ width: "150px" }}>Total:</Typography>
                                                <Typography>{orderTotalFormatted}</Typography>
                                            </Stack>
                                            <Button variant="contained" color="primary" sx={{ width: 150, marginTop: "8px" }} onClick={placeOrder}>Submit Order</Button>
                                        </Paper>
                                    </AccordionDetails>
                                </Accordion>
                            }
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMsg}
                action={snackbarAction}
            />
        </Container>
    );
};

export default ListBuilder;