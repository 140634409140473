import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import LoadingSpinner from "../components/LoadingSpinner";
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
//import useAuth from "../hooks/useAuth";
import { format, parse } from 'date-format-parse';
import { useMyOrders } from '../hooks/useMyOrders';
import ErrorFallBack from "../components/ErrorFallBack";
import DownloadIcon from "@mui/icons-material/Download";
import { useOrder } from "../hooks/useOrder";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import Modal from '@mui/material/Modal';
import ActionDialog from "../components/ActionDialog";
import { Order } from '../models/OrdersResponse';
import { DataGridPremium, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import { Storefront } from "../models/StorefrontResponse";
import { Typography } from "@mui/material";
import OrderDetails from "../components/OrderDetails";
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import { useErrorLog } from "../hooks/useErrorLog";
import { useConsoleLogHistory } from "../hooks/useConsoleLogHistory";


// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
  storefront: Storefront
  userID: string
}

const MyOrders = (props: Props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("")
  const [refreshKey, setRefreshKey] = useState(0);
  //const { user  } = useAuth();
  const [orderID, setOrderID] = useState("");
  const { order } = useOrder(orderID, true);
  const [action, setAction] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("")
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [openActionDialog, setOpenActionDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>()
  //const [linkClass, setLinkClass] = useState("link_lightmode");
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [orderDetailsID, setOrderDetailsID] = useState("");
  const [errorMessage, setErrorMessage] = useState("")
  const logs = useConsoleLogHistory();
  const { errorLogAcknowledged } = useErrorLog("MyOrders", errorMessage, username, logs)


  useEffect(() => {
    try {
      setUsername(props.userID);
    }
    catch(e) {
      console.log(e)
      navigate("/signin")
    }
  })
  
  useEffect(() => {
    // Log Pageview with Hubspot
    const _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/myorders' + location.search]);
    _hsq.push(['trackPageView']);

    // const colorMode = localStorage.getItem('colorMode')
    // if (colorMode == "dark") {
    //     setLinkClass("link_darkmode")
    // } else {
    //     setLinkClass("link_lightmode")
    // }
    
  }, []);
  
  useEffect(() => {
   //console.log("download logged")
  }, [order]);

  useEffect(() => {
    if (errorMessage != "") {
      console.log("errorLogSubmitted: result=" + errorLogAcknowledged + " - '" + errorMessage + "'")
    }
  }, [errorLogAcknowledged]);

  const { orders, loading, error } = useMyOrders(username, refreshKey);

  useEffect(() => {
   setErrorMessage(error)
  }, [error]);

  const columns = [
        { field: 'id', headerName: 'ID', width: 147, renderCell: (params: GridRenderCellParams) => {
      return         <Link
          component="button"
          variant="body2"
          onClick={() => {
            if (params.value != "TSG-20240420-SMPL") {
              setOrderDetailsID(params.value);
              setOpenOrderDetails(true);
            }
          }}
          >
          {params.value}
        </Link>
    } },
    { field: 'orderDate', headerName: 'Date', width: 85, valueGetter: (params: GridValueGetterParams) => {
      return getShortDate(params.value)
    }, },
    { field: 'description', headerName: 'Description', flex: 1, width: 340 },
    { field: 'totalPrice', headerName: 'Amount', width: 100, valueGetter: (params: GridValueGetterParams) => {
      return formatter.format(params.value);
    },},
    { field: 'receiptURL', headerName: 'Receipt', width: 80, renderCell: (params: GridRenderCellParams) => {
      if (params.value == undefined || params.value == "") {
        return <div></div>
      } else {
        return <Button href={`${params.value}`} download={"TSG Receipt"} size="small">
          <DownloadIcon color="primary" />
        </Button>
      }
    },},
    { field: 'fulfillment', headerName: 'Download', width: 80, renderCell: (params: GridRenderCellParams) => {
      if (params.value.downloadURL == "na") {
        return <div></div>
      } else if (params.value.downloadURL != "") {
        return <Button href={`${params.value.downloadURL}`} download={"TSG Order"} size="small" onClick={() => {if (params.id != "TSG-20240420-SMPL") {logDownload(params.row.id)}}}>
          <DownloadIcon color="primary" />
        </Button>
      } else {
        return <div>Pending...</div>
      }
    }, },
  ]

  function getShortDate(dateString: string | undefined) {
    if (dateString != undefined) {
      const dt = parse(dateString, 'YYYY-MM-DDTHH:mm:ss');
      return format(dt, "MM/DD/YY")
    }
  }

  // const getReceiptLink = (url: string) => {
  //   if (url != undefined && url != "") {
  //     return (<a href={url} target="_blank" className={linkClass} >view</a>)
  //   } else {
  //     return <div />
  //   }
  // }

  const handleActionChange = (event: SelectChangeEvent) => {
    if (selectedOrders.length == 0) {
      setSnackbarMsg("Please select an order and then try again")
      setOpenSnackbar(true)
      return
    } else if (selectedOrders.length > 1) {
      setSnackbarMsg("Only one order can be processed at a time. Select only one order and try again")
      setOpenSnackbar(true)
      return
    }

    // Find the order
    for (const myOrder of orders) {
      if (myOrder != undefined) {
        if (myOrder.id == selectedOrders[0]) {
          setSelectedOrder(myOrder)
          break
        }
      }
    }
    console.log("action requested:", event.target.value, ", orderID:", selectedOrders[0])

    const selAction = event.target.value as string

    if (selAction == "view_leads") {
      navigate("/leadsviewer")
      return
    }
    
    setSelectedAction(selAction);
    setAction("")
    handleActionDialogOpen()
  };

  function logDownload(orderID: string) {
    console.log('logging download for orderID #', orderID)
    setOrderID(orderID)
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const handleRefreshClick = () => {
    setRefreshKey(refreshKey+1);
  };

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };
  
  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#303030',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
  };

  const handleActionDialogOpen = () => setOpenActionDialog(true);
  const handleActionDialogClose = () => setOpenActionDialog(false);

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box width={100}>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 640" enableBackground="new 0 0 512 512"><g><g>
            <polygon points="448,384 181.531,384 69.531,128 0,128 0,96 90.469,96 202.469,352 448,352   " fill="#797a7a"/></g>
            <path d="M288,464c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S288,437.5,288,464z" fill="#797a7a"/>
            <path d="M448,464c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S448,437.5,448,464z" fill="#797a7a"/><g>
              <path d="M400,0c-61.844,0-112,50.156-112,112s50.156,112,112,112s112-50.156,112-112S461.844,0,400,0z M456.563,145.938    l-22.625,22.625L400,134.625l-33.938,33.938l-22.625-22.625L377.375,112l-33.938-33.938l22.625-22.625L400,89.375l33.938-33.938    l22.625,22.625L422.625,112L456.563,145.938z" fill="#797a7a"/></g>
              <path d="M400,256c-62.5,0-115.281-40.25-135.156-96H160l64,160h224l36.938-92.313C461.063,245.25,431.844,256,400,256z" fill="#797a7a"/></g>
          </svg>
        </Box>
        <Box sx={{ mt: 1, color:"#797a7a" }}>No Purchases</Box>
      </StyledGridOverlay>
    );
  }

  const orderDetailsStyle = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: props.storefront.activeColors.addProductForm.formBackground,
    border: '1px solid #5c5c5e',
    boxShadow: 24,
    p: 1,
  };

  const handleOrderDetailsClose = () => setOpenOrderDetails(false);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Modal
                open={openActionDialog}
                onClose={handleActionDialogClose}
              >
                <Box sx={style}>
                  <ActionDialog Order={selectedOrder} Action={selectedAction} closeDialogCallback={handleActionDialogClose}></ActionDialog>
                </Box>
              </Modal>
              <Typography variant="h6" sx={{color: props.storefront.activeColors.mainPage.pageText}}>My Purchases</Typography>
              <Stack direction="row">
                <IconButton size="medium" type="button" onClick={() => handleRefreshClick()} sx={{height:40, marginTop:1}}>
                  <RefreshIcon sx={{color:props.storefront.activeColors.mainPage.pageIconBackground}} />
                </IconButton>
                { props.storefront.storeName == "highiq" && 
                  <Box sx={{ minWidth: 120, marginRight: 1 }}>
                  
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Actions</InputLabel>
                      <Select
                        value={action}
                        label="Action"
                        onChange={handleActionChange}
                      >
                        <MenuItem value={"export_highiq"}>Export to High i.Q.</MenuItem>
                        {/* <MenuItem value={"view_leads"}>View Leads on Map</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Box>
                }
              </Stack>
            </Stack>
            <Grid item xs={12}>
              <Paper sx={{ paddingTop: 2, display: "flex", flexDirection: "column" }}>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                  item
                  xs={12}
                >
                </Grid>
                <Grid height={500}>
                  {loading
                    ? <LoadingSpinner title="Loading Orders..." textColor="#0352fc" />
                    : error
                    ? <ErrorFallBack message={error}/>
                    :
                    <DataGridPremium
                    rows={orders}
                    columns={columns}
                    checkboxSelection
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                      },
                      sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                      },
                    }}
                    pageSizeOptions={[5, 10, 25, 50]}
                    onRowSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const arrayIDs: string[] = [];
                      selectedIDs.forEach(v => arrayIDs.push(v.toString()));
                      setSelectedOrders(arrayIDs)
                    }}
                  />
                    }
                  </Grid>
                  <Snackbar
                      open={openSnackbar}
                      autoHideDuration={6000}
                      onClose={handleSnackbarClose}
                      message={snackbarMsg}
                      action={snackbarAction}
                    />
              </Paper>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Modal
          open={openOrderDetails}
          onClose={handleOrderDetailsClose}
      >
          <Box sx={orderDetailsStyle}>
              <OrderDetails orderID={orderDetailsID} showFullDetails={false} close={() => {setOpenOrderDetails(false)}} />
          </Box>
      </Modal>
    </Container>
  );
};

export default MyOrders;