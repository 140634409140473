import {useState, useEffect} from 'react';
import { APIEndpoint, EndpointType, httpGetNoAuth } from '../utils/apiService'
import { Questionnaire, QuestionnairesResponse } from '../models/QuestionnairesResponse';

const apiURL =  APIEndpoint(EndpointType.Questionnaire);

export const useQuestionnaire = (questionnaireID: string) => {
    const [questionnaire, setQuestionnaire] = useState<Questionnaire>()
    const [questionnaireError, setError] = useState("")
    const [questionnaireLoading, setLoading] = useState(false)

    useEffect(() => {        
        if (questionnaireID == "") {
            return
        }
        setLoading(true)
        setError("")
        httpGetNoAuth(apiURL + "/" + questionnaireID)
            .then((data) => {
                const questionnairesResponse = data as QuestionnairesResponse;
                
                if (questionnairesResponse.status == "error") {
                    setError(questionnairesResponse.errorMessage)
                    return
                }
                if (questionnairesResponse.questionnaires) {
                    if (questionnairesResponse.questionnaires.length > 0) {
                        setQuestionnaire(questionnairesResponse.questionnaires[0]);
                    }
                } else {
                    setQuestionnaire({} as Questionnaire)
                }
                
            })
            .catch((error) => setError(error + ""))
            .finally(() => setLoading(false))

    }, [questionnaireID]);

    return { questionnaire, questionnaireLoading, questionnaireError };
};