
export interface SuppressionListResponse {
    status: string,
    requestID: string,
    timestamp: string,
    errorMessage: string,
    suppressions: SuppressionList[],
}

export interface SuppressionList {
    id: string,
    createdAt: string,
    updatedAt: string,
    referenceID: string,
    listID: number,
    s3Uri: string,
    columnMappings: { [key: string]: number },
    status: SuppressionListStatus,
    fields: string[],
    recordCount: number,
    info: string
}

export enum SuppressionListStatus { 
    completed = "completed",
    error = "error",
    pending = "pending",
    requested = "requested",
    ready_for_processing = "ready_for_processing",
    unknown = ""
}

