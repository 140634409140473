import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Box';
import { WalletTransaction } from '../models/Wallet';
import { Stack, Tooltip } from '@mui/material';
import { format } from 'date-format-parse';
import { DataGridPremium, GridValueGetterParams, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import { LocalTime } from '../utils/localTime';

interface Props {
  transactions: WalletTransaction[]
  height: number
}

// const ODD_OPACITY = 0.5;

// const StripedDataGrid = styled(DataGridPremium)(({ theme }) => ({
//     [`& .${gridClasses.row}.even`]: {
//       backgroundColor: theme.palette.grey[200],
//       '&:hover, &.Mui-hovered': {
//         backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
//         '@media (hover: none)': {
//           backgroundColor: 'transparent',
//         },
//       },
//       '&.Mui-selected': {
//         backgroundColor: alpha(
//           theme.palette.primary.main,
//           ODD_OPACITY + theme.palette.action.selectedOpacity,
//         ),
//         '&:hover, &.Mui-hovered': {
//           backgroundColor: alpha(
//             theme.palette.primary.main,
//             ODD_OPACITY +
//               theme.palette.action.selectedOpacity +
//               theme.palette.action.hoverOpacity,
//           ),
//           // Reset on touch devices, it doesn't add specificity
//           '@media (hover: none)': {
//             backgroundColor: alpha(
//               theme.palette.primary.main,
//               ODD_OPACITY + theme.palette.action.selectedOpacity,
//             ),
//           },
//         },
//       },
//     },
//   }));

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

export default function WalletTransactionList(props: Props) {

    const columns = [
        { field: 'createdAt', headerName: 'Date', width: 80, renderCell: (params: GridRenderCellParams) => {
          return <div>
            <Tooltip title={LocalTime(params.value)} placement="bottom-start">
              <Stack>
                <Box>{getShortDate(params.value)}</Box>
                <Box>{getTime(params.value)}</Box>
            </Stack>
            </Tooltip>
            </div>
        } },
        { field: 'orderDate', headerName: 'Description', width: 290, renderCell: (params: GridRenderCellParams) => {
          return <Stack>
          <Box>{getFriendlyTransactionType(params.row)}</Box>
          <Box>{getNotes(params.row)}</Box>
        </Stack>
        }, },
        { field: 'amount', headerName: 'Amount', width: 110,  valueGetter: (params: GridValueGetterParams) => {
            return getAmount(params.row)
          }, },
      ]

    const getShortDate = (dateString: string | undefined) => {
        if (dateString != undefined) {
            const utcDate = new Date(dateString);
            return format(utcDate, "MM/DD/YY")
        }
    }

    const getTime = (dateString: string | undefined) => {
        if (dateString != undefined) {
            const utcDate = new Date(dateString);
            return format(utcDate, "hh:mm A")
        }
    }

    const getAmount = (transaction: WalletTransaction) => {
        if (transaction.type == "transfer") {
            if (transaction.customerID == transaction.transfer?.fromID) {
                if (transaction.amount) {
                    return (transaction.amount * -1).toLocaleString()
                }
            }
        } else if (transaction.type == "refund" || transaction.type == "payment") {
            if (transaction.amount) {
                return (transaction.amount * -1).toLocaleString()
            }
        } else {
          if (transaction && transaction.amount) {
            return transaction.amount.toLocaleString()
          }
        }

        return transaction.amount
    }

    const getFriendlyTransactionType = (transaction: WalletTransaction) => {
        switch (transaction.type) {
            case "promotion":
                switch (transaction.promotion?.promotionType) {
                    case "registration":
                        return "Registration Promotion"
                    case "survey":
                        return "Survey Promotion"
                    case "other":
                        return "Other Promotion"
                }
                break;
            case "deposit":
                return "Purchase Deposit"
            case "payment":
                return "Payment (withdrawal)"
            case "refund":
                return "Refund Deposit"
            case "transfer":
                if (transaction.customerID == transaction.transfer?.fromID) {
                    return "Transfer to " + transaction.transfer?.toName
                } else {
                    return "Transfer from " + transaction.transfer?.fromName
                }
            case "warranty":
                return "Warranty Deposit"
        }
    }

    const getNotes = (transaction: WalletTransaction) => {
      // NOTE: This was commented out because it redirects to the admin page for order details.
      // This should redirect to a public-facing order details page whenever that is completed.
      // if (transaction.type == "payment") {
      //   return <Stack direction='row'><Typography>Order #</Typography><a href={"/order?order=" + transaction.payment?.orderID}>{transaction.payment?.orderID}</a></Stack>
      // }

      return transaction.notes
  }

    function CustomNoRowsOverlay() {
        return (
          <StyledGridOverlay>
            <Box sx={{ mb: 1, color:"#797a7a" }}>No Transactions</Box>
            <Box width={100}>
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="0 0 512 640" enableBackground="new 0 0 512 512"><g><g>
                <polygon points="448,384 181.531,384 69.531,128 0,128 0,96 90.469,96 202.469,352 448,352   " fill="#797a7a"/></g>
                <path d="M288,464c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S288,437.5,288,464z" fill="#797a7a"/>
                <path d="M448,464c0,26.5-21.5,48-48,48s-48-21.5-48-48s21.5-48,48-48S448,437.5,448,464z" fill="#797a7a"/><g>
                  <path d="M400,0c-61.844,0-112,50.156-112,112s50.156,112,112,112s112-50.156,112-112S461.844,0,400,0z M456.563,145.938    l-22.625,22.625L400,134.625l-33.938,33.938l-22.625-22.625L377.375,112l-33.938-33.938l22.625-22.625L400,89.375l33.938-33.938    l22.625,22.625L422.625,112L456.563,145.938z" fill="#797a7a"/></g>
                  <path d="M400,256c-62.5,0-115.281-40.25-135.156-96H160l64,160h224l36.938-92.313C461.063,245.25,431.844,256,400,256z" fill="#797a7a"/></g>
              </svg>
            </Box>
          </StyledGridOverlay>
        );
      }
    
  return (
    <Grid height={props.height}>
        {/* { props.transactions.length > 0
            ?   */}
            <DataGridPremium
            rows={props.transactions}
            columns={columns}
            hideFooter
            slots={{
                noRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        {/* //   : <Typography>No Transactions</Typography>
        // } */}
    </Grid>
  );
}
