import {useState, useEffect} from 'react';
import { httpGet, APIEndpoint, EndpointType } from '../utils/apiService'
import { Order, OrdersResponse } from '../models/OrdersResponse'

const apiURL =  APIEndpoint(EndpointType.MyOrders);


export const useMyOrders = (customerID: string, refreshKey: number) => {
    const [orders, setMyOrders] = useState<Order[]>([])
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    
    function createSampleOrder(): Order {
        return {
            id: "TSG-20240420-SMPL",
            customerID: "1",
            orderDate: "2024-04-23T18:25:56.179Z",
            description: "Sample Order",
            totalPrice: 123.45,
            fulfillment: {
                id: "TSG-20240420-smpl",
                type: "download",
                createdAt: "2024-04-23T18:25:56.179Z",
                downloadURL: "https://orders-thesharegroup-prod.s3.us-west-2.amazonaws.com/20240420-TSG-20240420-SMPL/order_TSG-20240420-SMPL.zip",
                downloads: []
            },
        }
    }

    useEffect(() => {
        if (!customerID) return;
        const url = apiURL + customerID

        setLoading(true)
        httpGet(url)
            .then((data) => {
                try {
                    const myOrdersResponse = data as OrdersResponse;

                    if (myOrdersResponse.status === 'error') {
                        setError("service error - " + myOrdersResponse.errorMessage)
                        setMyOrders([])
                    } else {
                        if (myOrdersResponse.orders == undefined) {
                            myOrdersResponse.orders = [createSampleOrder()]
                            setMyOrders([createSampleOrder()])
                        } else {
                            setMyOrders(myOrdersResponse.orders);
                        }
                    }
                }
                catch(error) {
                    const emptyOrder: Order[] = []
                    setMyOrders(emptyOrder)
                    setError("No orders were found")
                }
            })
            .catch((error) => {
                if (error instanceof Error) {
                    console.log("error contacting service: ", error.message)
                    setError("client error - " + error.message + " (" + url + ")" + " - " + error.name)
                }
                setMyOrders([])
            })
            .finally(() => setLoading(false))

    }, [customerID, refreshKey]);

    return { orders, loading, error };
};

