import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Title from '../components/Title';
import { useState, useEffect } from 'react';
import LoadingSpinner from "../components/LoadingSpinner";
import { AutocompleteUser, useUsers } from "../hooks/useUsers";
import { useWalletBalance } from "../hooks/useWalletBalance";
import { useWalletTransactions } from "../hooks/useWalletTransactions";
import Autocomplete from "@mui/material/Autocomplete";
import { Storefront } from "../models/StorefrontResponse";
import WalletTransactionList from "../components/WalletTransactionList";
import { MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';
import { CreateTransactionRequest, WalletBalance, WalletTransactionResponse } from "../models/Wallet";
import useAuth from "../hooks/useAuth";
import { httpPost, APIEndpoint, EndpointType } from "../utils/apiService";

interface Props {
    storefront: Storefront
    walletBalanceChanged(): void
}

const WalletManagement = (props: Props) => {
  const [balanceRefreshKey, setBalanceRefreshKey] = useState(0);
  const [transactionsRefreshKey, setTransactionsRefreshKey] = useState(0);
  const [customerID, setCustomerID] = useState("");
  const [transferTo, setTransferTo] = useState("");
  const [amount, setAmount] = useState("");
  const [depositTransactionID, setDepositTransactionID] = useState("");
  const [depositAmountPaid, setDepositAmountPaid] = useState("");
  const [notes, setNotes] = useState("");
  // const [userInfo, setUserInfo] = useState("")
  // const [transferToUserInfo, setTransferToUserInfo] = useState("")
  const [transactionType, setTransactionType] = useState("")
  const [transactionDescription, setTransactionDescription] = useState("")
  const [promotionType, setPromotionType] = useState("other")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState("")
  const [orderRefID, setOrderRefID] = useState("")
  const { user  } = useAuth();
  const { users, autocompleteUsers} = useUsers(0);
  const { walletBalance } = useWalletBalance(customerID, balanceRefreshKey)
  const { walletTransactions, walletTransactionsLoading } = useWalletTransactions(customerID, transactionsRefreshKey)


  useEffect(() => {
    console.log("wallet customerID changed: " + customerID)
  }, [customerID]);

  useEffect(() => {
    updateTransactionDescription()
  }, [transactionType]);

  useEffect(() => {
    console.log("TransactionDescription: " + transactionDescription)
  }, [transactionDescription]);

  const handleTransTypeChange = (event: SelectChangeEvent) => {
    if (event.target.value != undefined) {
        setTransactionType(event.target.value)
    }
  };

  const handlePromotionTypeChange = (event: SelectChangeEvent) => {
    setPromotionType(event.target.value)
  };

  const handleAmountChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setAmount(event.target.value)
  }

  const handleNotesChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setNotes(event.target.value)
  }

  const handleTransIDChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setDepositTransactionID(event.target.value)
  }

  const handleDepositAmountChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setDepositAmountPaid(event.target.value)
  }

  const handleOrderRefIDChanged = (event: React.ChangeEvent<{ value: string }>) => {
    setOrderRefID(event.target.value)
  }

  const customerIDChanged = (event: React.SyntheticEvent<Element, Event>, value: AutocompleteUser | null) => {
    if (value == null) {
      setCustomerID("")
    } else {
      setCustomerID(value.id)

      // Find the user get their phone/email
      // for (let user of users) {
      //   if (user.id == value.id) {
      //     let phone: string = "none";

      //     if (user.phone != "") {
      //       phone = user.phone;
      //     }
      //     //setUserInfo("Phone: " + phone + ", Email: " + user.email)
      //   }
      // }
    }
  }

  const transferToChanged = (event: React.SyntheticEvent<Element, Event>, value: AutocompleteUser | null) => {
    if (value == null) {
      setTransferTo("")
    } else {
        setTransferTo(value.id)

      // Find the user get their phone/email
      // for (let user of users) {
      //   if (user.id == value.id) {
      //     let phone: string = "none";

      //     if (user.phone != "") {
      //       phone = user.phone;
      //     }
      //     setTransferToUserInfo("Phone: " + phone + ", Email: " + user.email)
      //   }
      // }
    }
  }

  const getWalletBalance = (wb: WalletBalance | undefined) => {
    if (wb != undefined && wb.balance != undefined) {
        return wb.balance + " leads"
    }

    return "0 leads"
  }

  const createTransactionClicked = () => {
    console.log("Creating transaction...")
    const createTransactionRequest: CreateTransactionRequest = {
        initiatedBy: user.username,
        notes: notes,
        customerID: customerID,
        transactionType: transactionType
    }

    if (customerID == "") {
        setSnackbarMsg("A customer must be selected")
        setOpenSnackbar(true)
        return
    }

    if (transactionType == "") {
        setSnackbarMsg("Transaction Type must be selected")
        setOpenSnackbar(true)
        return
    }

    const transAmount = parseInt(amount)
    if (isNaN(transAmount) || transAmount <= 0) {
        setSnackbarMsg("The amount specified must be a positive number")
        setOpenSnackbar(true)
        return
    } else {
        createTransactionRequest.amount = transAmount;
    }

    if (transactionType == "promotion") {
        if (promotionType == "") {
            setSnackbarMsg("The Promotion Type must be specified")
            setOpenSnackbar(true)
            return
        }
        createTransactionRequest.promotionType = promotionType
    }

    if (transactionType == "payment") {
        if (orderRefID == "") {
            setSnackbarMsg("The OrderID/Reference# must be specified")
            setOpenSnackbar(true)
            return
        }
        createTransactionRequest.orderID = orderRefID
    }

    if (transactionType == "transfer") {
        if (transferTo == "") {
            setSnackbarMsg("Please select the customer to transfer leads to")
            setOpenSnackbar(true)
            return
        }

        if (walletBalance != undefined && walletBalance.balance != undefined && transAmount > walletBalance?.balance) {
            setSnackbarMsg("The amount of leads to transfer must be less than equal to the leads balance available")
            setOpenSnackbar(true)
            return 
        }

        createTransactionRequest.toCustomerID = transferTo;
        createTransactionRequest.fromCustomerID = customerID;
        createTransactionRequest.toCustomerName = getUserName(transferTo);
        createTransactionRequest.fromCustomerName = getUserName(customerID);
    }

    if (transactionType == "deposit") {
      if (depositTransactionID == "") {
        setSnackbarMsg("Please specify the transaction ID associated with Quicken or other payment system. Specify 'Unknown' if you do not have this information.")
        setOpenSnackbar(true)
        return 
      }

      if (depositAmountPaid == "") {
        setSnackbarMsg("Please specify the dollar amount paid for the credits!")
        setOpenSnackbar(true)
        return 
      }

      const amountPaid: number = +depositAmountPaid

      console.log("amount paid: ", amountPaid)
      if (amountPaid == 0 || Number.isNaN(amountPaid)) {
        setSnackbarMsg("The deposit amount must be a valid number.")
        setOpenSnackbar(true)
        return
      }

      createTransactionRequest.paymentAmount = amountPaid
      createTransactionRequest.paymentIntentID = depositTransactionID
    }

    const apiURL =  APIEndpoint(EndpointType.WalletTransactions);
    httpPost(apiURL, JSON.stringify(createTransactionRequest))
    .then((data) => {
        const walletTransactionsResponse = data as WalletTransactionResponse;
        
        if (walletTransactionsResponse.status == 'error') {
            setSnackbarMsg("Error creating transaction! " + walletTransactionsResponse.errorMessage)
            setOpenSnackbar(true)
        } else {
          props.walletBalanceChanged()
          setSnackbarMsg("Transaction created successfully!")
          setOpenSnackbar(true)
          setBalanceRefreshKey(balanceRefreshKey+1)
          setTransactionsRefreshKey(transactionsRefreshKey+1)
        }
    })
    .catch((error) => { 
      setSnackbarMsg(error)
      setOpenSnackbar(true)
    });
  }

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const updateTransactionDescription = () => {
    console.log("TransactionType: " + transactionType)
    switch (transactionType) {
      case "deposit":
        setTransactionDescription("The Purchase Credits transaction is used to add leads to a wallet when a customer has actually paid for them.")
        break
      case "payment":
        setTransactionDescription("The Payment transaction is used to deduct leads from a wallet when a customer has purchased leads.")
        break
      case "promotion":
        setTransactionDescription( "The Promotion transaction is used to add leads to a wallet for promotional purposes.")
        break
      case "refund":
        setTransactionDescription("The Refund transaction is used to remove leads from a wallet when a customer has been refunded money for a previous purchase.")
        break
      case "transfer":
        setTransactionDescription("The Transfer transaction is used to move leads from one wallet to another wallet.")
        break
      case "warranty":
        setTransactionDescription("The Warranty transaction is used to add leads to a wallet when a customer has been given leads as a warranty, for example, when leads they purchased are bad/disconnects.")
        break
      default:
        setTransactionDescription("")
    }
  }

  const snackbarAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const getUserName = (id: string): string => {
    for (const user of users) {
        if (id == user.id) {
            return user.firstName + " " + user.lastName
        }
    }

    return "unknown"
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Title>Wallet Management</Title>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="stretch" justifyContent="flex-start">
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6">Customer Wallet Details</Typography>
                    <Box sx={{marginTop:2}}>
                        <Autocomplete disablePortal isOptionEqualToValue={(option: AutocompleteUser, value: AutocompleteUser) => option.id === value.id} id="existingCustomer" onChange={customerIDChanged} options={autocompleteUsers} size="small"  sx={{ width: 360 }}
                        renderInput={(params) => <TextField {...params} label="Customer Name" /> }
                        />
                        {/* <Typography sx={{}}>{userInfo}</Typography> */}
                    </Box>
                    <Box sx={{marginTop:2}}>
                        <Stack direction="row" spacing={2}>
                            <Typography sx={{fontWeight:"bold"}}>Balance:</Typography>
                            <Typography>{getWalletBalance(walletBalance)}</Typography>
                        </Stack>
                    </Box>
                    <Box sx={{marginTop:2, minWidth:460}}>
                        <Stack direction="column" spacing={0}>
                            <Typography sx={{fontWeight:"bold"}}>Transactions:</Typography>
                            { walletTransactionsLoading
                              ? <LoadingSpinner title="Loading wallet..." textColor="#0352fc"/>
                              : <WalletTransactionList transactions={walletTransactions} height={300} />
                            }
                        </Stack>
                    </Box>
                </Paper>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6">Create Transaction</Typography>
                    <Stack sx={{marginTop:2}} spacing={2}>
                        <FormControl fullWidth size="small" sx={{width:200}}>
                            <InputLabel id="trans-type-label">Transaction Type</InputLabel>
                            <Select labelId="transType" label="Transaction Type" onChange={handleTransTypeChange} size="small" value={transactionType}>
                              <MenuItem value="deposit">Purchase Credits</MenuItem>
                              <MenuItem value="payment">Payment</MenuItem>
                              <MenuItem value="promotion">Promotion</MenuItem>
                              <MenuItem value="refund">Refund</MenuItem>
                              <MenuItem value="transfer">Transfer</MenuItem>
                              <MenuItem value="warranty">Warranty</MenuItem>
                            </Select>
                        </FormControl>
                        { transactionDescription != "" &&
                          <Typography variant="caption">{transactionDescription}</Typography>
                        }
                        <TextField label="# Credits" size="small" sx={{width:200}} value={amount} onChange={handleAmountChanged}></TextField>
                        { transactionType == "transfer"
                          ? <Box sx={{marginTop:0}}>
                                <Autocomplete disablePortal isOptionEqualToValue={(option: AutocompleteUser, value: AutocompleteUser) => option.id === value.id} id="transferTo" onChange={transferToChanged} options={autocompleteUsers} size="small"  sx={{ width: 360 }}
                                renderInput={(params) => <TextField {...params} label="Transfer To" /> }
                                />
                                {/* <Typography sx={{}}>{transferToUserInfo}</Typography> */}
                            </Box>
                          : transactionType == "promotion"
                          ? <Box>
                                <FormControl fullWidth size="small" sx={{width:200}}>
                                    <InputLabel id="promotion-type-label">Promotion Type</InputLabel>
                                    <Select labelId="promotionType" label="Promotion Type" onChange={handlePromotionTypeChange} size="small" value={promotionType}>
                                      <MenuItem value="referral">Referral</MenuItem>
                                      <MenuItem value="registration">Registration</MenuItem>
                                      <MenuItem value="survey">Survey</MenuItem>
                                      <MenuItem value="testing">Testing</MenuItem>
                                      <MenuItem value="other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                          : transactionType == "payment"
                          ? <Box>
                              <TextField label="OrderID/Reference#" size="small" sx={{width:200, mt:2}} value={orderRefID} onChange={handleOrderRefIDChanged}></TextField>
                            </Box>
                          : transactionType == "deposit"
                          ? <Box>
                              <FormControl fullWidth size="small" sx={{width:200}}>
                                {/* <InputLabel id="promotion-type-label">Promotion Type</InputLabel> */}
                                <TextField label="Quicken Transaction ID" size="small" sx={{width:200}} value={depositTransactionID} onChange={handleTransIDChanged}></TextField>
                                <TextField label="Amount Paid" size="small" sx={{width:200, mt:2}} value={depositAmountPaid} onChange={handleDepositAmountChanged}></TextField>
                            </FormControl>
                          </Box>
                          : <Box />
                        }
                        <TextField label="Notes" size="small" sx={{width:300}} value={notes} onChange={handleNotesChanged}></TextField>
                        <Button variant="outlined" onClick={createTransactionClicked}>Create Transaction</Button>
                    </Stack>
                </Paper>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={snackbarAction}
    />
    </Container>
  );
};

export default WalletManagement;
