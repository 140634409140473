import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Order } from '../models/OrdersResponse';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ExportToHighIQ from './ExportToHighIQ';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

interface ActionDialogProps {
  Order?: Order,
  Action?: string
  closeDialogCallback(): void
}

const getActionDescription = (action?: string) => {
  switch (action) {
    case "export_highiq":
      return "Export Leads to High i.Q."
    default:
      return "Unknown Action"
  }
};



export default function ActionDialog(props: ActionDialogProps) {
  const handleDialogClose = () => {
    props.closeDialogCallback()
  };

  console.log("orderid: ", props.Order?.id)
  return (
    <Stack>
      <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          {getActionDescription(props.Action)}
        </Typography>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => handleDialogClose()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
      </Box>
      <Typography component="h3" color="primary" gutterBottom>
        Order ID: {props.Order?.id}
      </Typography>
      { props.Action == "export_highiq"
        ? <ExportToHighIQ Order={props.Order} ></ExportToHighIQ>
        : <Typography> </Typography>
      }
    </Stack>

  );
}
