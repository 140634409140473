import {useState, useEffect} from 'react';
import { httpPostNoAuth, APIEndpoint, EndpointType, getEnvironment } from '../utils/apiService'
import { ErrorLogResponse, ErrorLogRequest, ClientErrorInfo } from '../models/ErrorLog';
import useAuth from './useAuth';

const apiURL =  APIEndpoint(EndpointType.ErrorLog);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useErrorLog = (page: string, errorMessage: string, userID: string, logs: any[]) => {
    const [errorLogAcknowledged, setErrorAcknowledged] = useState(false)
    const [errorLogError, setErrorLogError] = useState("")
    const [errorLogLoading, setErrorLogLoading] = useState(false)
    const { isAuthenticated } = useAuth();
    
    useEffect(() => {
        if (errorMessage == "") {
            return
        }
        
        const clientErrorInfo: ClientErrorInfo = {
            userID: userID,
            page: page,
            isAuthenticated: isAuthenticated
        }

        const errorLogRequest: ErrorLogRequest =  {
            errorMessage: errorMessage,
            source: "web_client",
            environment: getEnvironment(),
            clientInfo: clientErrorInfo,
            logs: logs
        }


        setErrorLogLoading(true)
        setErrorLogError("")
        httpPostNoAuth(apiURL, JSON.stringify(errorLogRequest))
            .then((data) => {
                const response = data as ErrorLogResponse;
                
                if (response.status == "error") {
                    setErrorLogError(response.errorMessage)
                    setErrorAcknowledged(false)
                } else {
                    if (response.acknowledged) {
                        setErrorAcknowledged(response.acknowledged);
                    } else {
                        setErrorAcknowledged(false)
                    }
                }
            })
            .catch((error) => setErrorLogError(error))
            .finally(() => setErrorLogLoading(false))

    }, [errorMessage]);

    return { errorLogAcknowledged, errorLogError, errorLogLoading };
};