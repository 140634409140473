import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import * as React from "react";
import { useQuestionnaire } from "../hooks/useQuestionnaire";
import ErrorFallBack from "./ErrorFallBack";
import LoadingSpinner from "./LoadingSpinner";
import { Button, ListItem, TextField } from "@mui/material";
import Rating from '@mui/material/Rating';
import { useState } from 'react';
import { Feedback, FeedbackQuestionResponse, FeedbackResponse } from "../models/FeedbackResponse";
import { APIEndpoint, EndpointType, httpPostNoAuth } from "../utils/apiService";
import useAuth from "../hooks/useAuth";

interface Props {
    questionnaireID: string;
}

const Questionnaire = (props: Props) => {
    const { questionnaire, questionnaireLoading, questionnaireError } = useQuestionnaire(props.questionnaireID)
    const [ responses, setResponses ] = useState<FeedbackQuestionResponse[]>([]);
    const { isAuthenticated, user } = useAuth();
    const [ showSubmitButton, setShowSubmitButton] = useState(true)
    
    const saveQuestion = (questionID: string, value: string | number | null, title: string, promptType: string) => {
        const newResponses = [...responses];

        let foundMatch = false;

        for (const resp of responses) {
            if (resp.questionID == questionID) {
                resp.value = value + "";
                foundMatch = true;
            }
        }

        if (!foundMatch) {
            const newResponse: FeedbackQuestionResponse = {
                questionID: questionID,
                value: value + "",
                title: title,
                promptType: promptType
            }

            newResponses.push(newResponse)
        }

        setResponses(newResponses)
    }

    const postResponse = () => {
        if (responses.length == 0) {
            return
        }

        setShowSubmitButton(false)

        const feedback: Feedback = {
            questionnaireID: props.questionnaireID,
            responses: responses
        }

        if(isAuthenticated) {
            feedback.email = user.attributes.email
            feedback.customerID = user.username
        }

        const apiURL = APIEndpoint(EndpointType.Feedback)
        httpPostNoAuth(apiURL+"", JSON.stringify(feedback))
        .then((data) => {
            const feedbackResponse = data as FeedbackResponse;
            
            if (feedbackResponse.status == 'error') {
                console.log("Error submitting feedback: " + feedbackResponse.errorMessage)
            } else {
                console.log("feedback submitted successfully")
            }
        })
        .catch((error) => { 
          console.log("Error: " + error)
        });
    }

    return (
        <Box>
            {questionnaireLoading
                    ? <LoadingSpinner title="Loading data..." textColor="#0352fc" />
                    : questionnaireError
                    ? <ErrorFallBack message={questionnaireError} />
                    : <Box>
                        <Typography>{questionnaire?.title}</Typography>
                        <List>
                            {questionnaire?.questions.map((q) => (
                                <ListItem key={q.id}>
                                    <Box>
                                        <Typography>{q.prompt}</Typography>
                                        { q.promptType == "rating"
                                            ?   <Rating onChange={(event, newValue) => { saveQuestion(q.id, newValue, q.title, q.promptType);}}
                                                />
                                            :   q.promptType == "text"
                                            ?   <TextField sx={{width:500}} multiline maxRows={4} onChange={(event: React.ChangeEvent<{ value: string }>) => { saveQuestion(q.id, event.target.value, q.title, q.promptType)}} />
                                            : <Box />
                                        }
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                        { showSubmitButton
                          ? <Button variant="contained" sx={{marginLeft:3}} onClick={postResponse}>Submit</Button>
                          : <Typography sx={{marginLeft:2}}>Thank you! We value your feedback.</Typography>
                        }
                        
                    </Box>
            }
        </Box>
    );
};

export default Questionnaire;