import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import * as React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import Title from '../components/Title';
import LoadingSpinner from "../components/LoadingSpinner";
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import ErrorFallBack from "../components/ErrorFallBack";
import { DataGridPremium, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import { Storefront } from "../models/StorefrontResponse";
import Modal from '@mui/material/Modal';
import { User } from "../models/UsersResponse";
import { useSuppressions } from "../hooks/useSuppressions";
import { format, parse } from "date-format-parse";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SuppressionUpload from "../components/SuppressionUpload";
import NoData from "../components/NoData";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { APIEndpoint, EndpointType, httpDelete } from "../utils/apiService";
import { SuppressionListResponse } from "../models/Suppression";
import { Alert } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any; // Needed for Hubspot

interface Props {
    storefront: Storefront
    User: User | undefined
}

const Suppressions = (props: Props) => {
    const [refreshKey, setRefreshKey] = useState(0);
    const [openSuppressionUpload, setOpenSuppressionUpload] = useState(false);
    const [warningMessage, setWarningMessage] = useState("")

    // Hooks
    const { suppressions, suppressionsLoading, suppressionsError } = useSuppressions(props.User?.id || "", refreshKey);

    useEffect(() => {
        // Log Pageview with Hubspot
        const _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/suppressions']);
        _hsq.push(['trackPageView']);
    }, []);

    const handleRefreshClick = () => {
        setRefreshKey(refreshKey + 1);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 164 },
        {
            field: 'createdAt', headerName: 'Date', width: 85, valueGetter: (params: GridValueGetterParams) => {
                return getShortDate(params.value)
            },
        },
        { field: 'recordCount', headerName: 'Records', width: 100 },
        { field: 'status', headerName: 'Status', width: 200 },
        { field: 'listID', headerName: 'List ID', width: 100 },
        { field: 'info', headerName: 'More Info', width: 300 },
        { field: 'delete', headerName: 'Delete', width: 80, renderCell: (params: GridRenderCellParams) => {
              return <Button onClick={() => deleteSuppression(params.row.id)}  size="small">
                <DeleteForeverIcon color="primary" />
              </Button>
          },},
    ]

    function getShortDate(dateString: string | undefined) {
        if (dateString != undefined) {
            const dt = parse(dateString, 'YYYY-MM-DDTHH:mm:ss');
            return format(dt, "MM/DD/YY")
        }
    }

    const modalStyle = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: props.storefront.activeColors.cart.cartBackground,
        border: '1px solid #5c5c5e',
        boxShadow: 24,
        p: 1,
    };

    const deleteSuppression = (id: string) => {
        if (!window.confirm("Are you sure you want to delete this suppression list?")) {
            return
        }

        try {
            const apiURL = APIEndpoint(EndpointType.Suppressions) + "/" + id;
            httpDelete(apiURL)
                .then((data) => {
                    const response = data as SuppressionListResponse;

                    if (response.status == 'error') {
                        setWarningMessage("Error creating transaction! " + response.errorMessage)
                    } else {
                        setRefreshKey(refreshKey + 1);
                    }
                })
                .catch((error) => {
                    setWarningMessage(error)
                });
            } catch (error) {
                setWarningMessage("failed to submit suppression list")
            }
    }

    const handleSuppressionUploadClose = () => {
        setOpenSuppressionUpload(false);
        setRefreshKey(refreshKey + 1);
    }

    return (
        <Container maxWidth="xl" disableGutters={true} >
            <Grid item xs={12}>
                <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <Title>Suppressions</Title>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                        }}>
                            <IconButton size="medium" type="button" onClick={() => handleRefreshClick()} sx={{ height: 40, marginTop: 1, mr: 1 }} >
                                <RefreshIcon color="primary" />
                            </IconButton>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                onClick={() => setOpenSuppressionUpload(true)}
                                sx={{}}
                                startIcon={<CloudUploadIcon />}
                            >
                                Upload file
                            </Button>
                        </Box>
                    </Stack>
                    {warningMessage != "" && <Alert variant="standard" severity="error" sx={{ ml: 1, mr: 1 }}>{warningMessage}</Alert>}
                    <Grid item xs={12} sx={{ mt: 2 }}  >
                        {suppressionsLoading
                            ? <LoadingSpinner title="Loading Suppressions..." textColor="#0352fc" />
                            : suppressionsError
                                ? <ErrorFallBack message={suppressionsError} />
                                :
                                <DataGridPremium autoHeight
                                    rows={suppressions}
                                    columns={columns}
                                    initialState={{
                                        sorting: {
                                            sortModel: [{ field: 'createdAt', sort: 'desc' }],
                                        },
                                    }}
                                    slots={{
                                        noRowsOverlay: NoData,
                                    }}
                                />
                        }
                    </Grid>
                </Paper>
            </Grid>
            <Modal
                open={openSuppressionUpload}
                onClose={handleSuppressionUploadClose}
            >
                <Box sx={modalStyle}>
                    <SuppressionUpload storefront={props.storefront} user={props.User} referenceID={props.User && props.User.id || ""} close={handleSuppressionUploadClose} />
                </Box>
            </Modal>
        </Container>
    );
};

export default Suppressions;